<template>
    <div class="d-flex align-items-center flex-wrap">
        <div class="col-12 col-lg-6 col-xl-4">
            <router-link to="/dashboard/travels/tickets" class="text-decoration-none" :class="{'disabled' : BankCards.length === 0}">
                <card :class="{'disabled' : BankCards.length === 0}">
                    <template v-slot:body>
                        <div class="d-flex flex-wrap">
                            <div class="col-12 text-center">
                                <DashboardIcon :icon="'tickets'" class="dashboard-icon"/>
                            </div>
                            <div class="col-12 text-center mt-4">
                                <h2 class="text-third">
                                    {{ $t('Dashboard.DashboardIndex.Cards.Tickets') }}
                                </h2>
                            </div>
                        </div>
                    </template>
                </card>
            </router-link>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
            <router-link to="/dashboard/travels/payments" class="text-decoration-none" :class="{'disabled' : BankCards.length === 0}">
                <card :class="{'disabled' : BankCards.length === 0}">
                    <template v-slot:body>
                        <div class="d-flex flex-wrap">
                            <div class="col-12 text-center">
                                <DashboardIcon :icon="'payments'" class="dashboard-icon"/>
                            </div>
                            <div class="col-12 text-center mt-4">
                                <h2 class="text-third">
                                    {{ $t('Dashboard.DashboardIndex.Cards.Payments') }}
                                </h2>
                            </div>
                        </div>
                    </template>
                </card>
            </router-link>
        </div>
        <div class="col-12 col-lg-6 col-xl-4 position-relative">
            <router-link to="/dashboard/travels/bank_cards" class="text-decoration-none">
                <card>
                    <template v-slot:body>
                        <div class="d-flex flex-wrap">
                            <div class="col-12 text-center">
                                <DashboardIcon :icon="'bankcard'" class="dashboard-icon"/>
                            </div>
                            <div class="col-12 text-center mt-4">
                                <h2 class="text-third">
                                    {{ $t('Dashboard.DashboardIndex.Cards.BankCards') }}
                                    <span class="badge bg-white text-second border border-second rounded-circle">
                                        {{ BankCards.length }}
                                    </span>
                                </h2>
                            </div>
                        </div>
                    </template>
                </card>
                <span class="badge badge-alert rounded-pill fs-2" v-if="BankCards.length === 0">
                    <i class="fa-solid fa-exclamation"></i>
                </span>
            </router-link>
        </div>
        <!-- <div class="col-12 col-lg-6 col-xl-4">
            <router-link to="/" class="text-decoration-none disabled">
                <card class="disabled">
                    <template v-slot:body>
                        <div class="d-flex flex-wrap">
                            <div class="col-12 text-center">
                                <img :src="require(`@/assets/img/dashboard/${omsApiKey}/subscriptions.png`)" class="dashboard-icon">
                            </div>
                            <div class="col-12 text-center mt-4">
                                <h2 class="text-third">
                                    {{ $t('Dashboard.DashboardIndex.Cards.Subscriptions') }}
                                </h2>
                            </div>
                        </div>
                    </template>
                </card>
            </router-link>
        </div> -->
        <div class="col-12 col-lg-6 col-xl-4">
            <router-link to="/dashboard/account" class="text-decoration-none">
                <card>
                    <template v-slot:body>
                        <div class="d-flex flex-wrap">
                            <div class="col-12 text-center">
                                <DashboardIcon :icon="'account'" class="dashboard-icon"/>
                            </div>
                            <div class="col-12 text-center mt-4">
                                <h2 class="text-third">
                                    {{ $t('Dashboard.DashboardIndex.Cards.Account') }}
                                </h2>
                            </div>
                        </div>
                    </template>
                </card>
            </router-link>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
            <card @click="this.$store.dispatch('logout')">
                <template v-slot:body>
                    <div class="d-flex flex-wrap">
                        <div class="col-12 text-center">
                            <DashboardIcon :icon="'logout'" class="dashboard-icon"/>
                        </div>
                        <div class="col-12 text-center mt-4">
                            <h2 class="text-third">
                                {{ $t('Dashboard.DashboardIndex.Cards.logout') }}
                            </h2>
                        </div>
                    </div>
                </template>
            </card>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Card from '@/components/Card.vue'
import DashboardIcon from '@/components/DashboardIcon.vue'
export default {
    name: 'DashboardIndex',
    components: {
        Card,
        DashboardIcon
    },
    computed: {
        ...mapState(['BankCards'])
    }
}
</script>

<style>

</style>