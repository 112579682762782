<template>
    <div class="frame m-4 p-4">
        <slot name="header">
        </slot>

        <slot name="body">
        </slot>
        
        <slot name="footer">   
        </slot>
    </div>
</template>

<script>
export default {
    name: 'CardComp'
}
</script>

<style lang="scss" scoped>
    .frame {
        position: relative;
        overflow: hidden;
        transition: 0.3s;
        background: linear-gradient(120deg, rgba(255, 255, 255, .25), rgba(255, 255, 255, .75), 70%, rgba(255, 255, 255, .25));
        border-radius: 1rem;
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(10px);
        cursor: pointer;

        &.disabled {
            opacity: 0.5;

            &:hover {
                transform: scale(1);
                
                &::before {
                    left: -125%;
                }
            }
        }

        &:hover {
            transform: scale(1.05);
            &::before {
                left: 150%;
            }
        }

        &::before {
            content: '';
            background: rgba(white, 0.4);
            width: 60%;
            height: 100%;
            top: 0%;
            left: -125%;
            transform: skew(45deg);
            position: absolute;
            transition: left 0.3s ease-out
        }
    }
</style>