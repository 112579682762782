<template>
    <svg id="layer" data-name="layer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 148.92 148.92">
        <g id="tickets" v-if="icon === 'tickets'">
            <path class="cls-2" d="m95.29,43.93H27.11c-5.07,0-9.17,4.11-9.17,9.17v8.81c6.93,0,12.55,5.62,12.55,12.55s-5.62,12.55-12.55,12.55v8.81c0,5.07,4.11,9.17,9.17,9.17h68.18v-61.05Z"/>
            <path class="cls-2" d="m130.98,61.91v-8.81c0-5.07-4.11-9.17-9.17-9.17h-22.11v61.05h22.11c5.07,0,9.17-4.11,9.17-9.17v-8.81c-6.93,0-12.55-5.62-12.55-12.55s5.62-12.55,12.55-12.55Z"/>
        </g>
        <g id="payments" v-if="icon === 'payments'">
            <g>
                <path class="cls-2" d="m75.74,75.43v8.34c1.28-.25,2.27-.77,2.97-1.54.7-.77,1.05-1.68,1.05-2.7,0-1.1-.34-1.96-1.02-2.56-.68-.6-1.68-1.11-3-1.53Z"/>
                <path class="cls-2" d="m119.23,48.16H29.69c-2.63,0-4.79,2.16-4.79,4.79v43.64c0,2.63,2.16,4.79,4.79,4.79h89.53c2.63,0,4.79-2.16,4.79-4.79v-43.64c0-2.63-2.16-4.79-4.79-4.79Zm-35.79,34.8c-.68,1.26-1.68,2.29-3,3.09-1.32.8-2.89,1.28-4.7,1.43v4.74c0,.75-.07,1.29-.22,1.64-.15.34-.47.51-.96.51-.46,0-.78-.14-.96-.42-.18-.28-.28-.71-.28-1.29v-5.14c-1.48-.16-2.78-.51-3.89-1.05-1.11-.53-2.04-1.2-2.78-1.99-.74-.79-1.29-1.62-1.65-2.47-.36-.86-.53-1.7-.53-2.52,0-.61.24-1.16.71-1.65.48-.49,1.07-.73,1.78-.73.57,0,1.05.13,1.45.4.39.27.67.64.82,1.12.34,1.04.64,1.84.89,2.39.25.55.64,1.06,1.15,1.51s1.2.81,2.05,1.05v-8.85c-1.7-.47-3.12-.99-4.26-1.57s-2.07-1.4-2.78-2.47c-.71-1.07-1.07-2.44-1.07-4.11,0-2.18.69-3.97,2.08-5.37s3.4-2.21,6.03-2.44v-2.02c0-1.07.4-1.6,1.2-1.6s1.22.52,1.22,1.56v2.06c1.36.14,2.52.42,3.49.84.97.42,1.82,1.05,2.54,1.9.57.65,1.01,1.31,1.32,2,.31.69.47,1.31.47,1.88,0,.63-.23,1.18-.69,1.65s-1.02.69-1.68.69c-1.24,0-2.05-.67-2.42-2.02-.42-1.59-1.43-2.64-3.03-3.16v7.92c1.57.43,2.83.82,3.76,1.18.93.36,1.77.87,2.5,1.54.79.7,1.39,1.53,1.82,2.5.43.97.64,2.03.64,3.19,0,1.45-.34,2.8-1.02,4.06Z"/>
                <path class="cls-2" d="m69.93,66.11c0,1,.28,1.76.84,2.27s1.41.98,2.55,1.4v-7.29c-1.07.32-1.9.74-2.49,1.26-.6.52-.89,1.31-.89,2.36Z"/>
            </g>
        </g>
        <g id="bankcard" v-if="icon === 'bankcard'">
            <g>
                <path class="cls-2" d="m105.95,46.67h-62.5c-6.05,0-11,4.95-11,11v.65h84.51v-.65c0-6.05-4.95-11-11-11Z"/>
                <path class="cls-2" d="m32.45,91.25c0,6.05,4.95,11.01,11,11.01h62.5c6.05,0,11-4.95,11-11.01v-21.72H32.45v21.72Zm54.74-5.19h24.22c1.56,0,2.83,1.27,2.83,2.83s-1.27,2.83-2.83,2.83h-24.22c-1.56,0-2.83-1.27-2.83-2.83s1.27-2.83,2.83-2.83Zm-22.9,0h11.32c1.56,0,2.83,1.27,2.83,2.83s-1.27,2.83-2.83,2.83h-11.32c-1.56,0-2.83-1.27-2.83-2.83s1.27-2.83,2.83-2.83Z"/>
            </g>
        </g>
        <g id="subscriptions" v-if="icon === 'subscriptions'">
            <rect class="cls-1" x="26.02" y="43.37" width="96.88" height="62.18" rx="12" ry="12"/>
            <path class="cls-2" d="m49.65,70.18c-6.63,0-12,8.62-12,19.26,0,1.67,5.37,3.02,12,3.02s12-1.35,12-3.02c0-10.64-5.37-19.26-12-19.26Z"/>
            <circle class="cls-2" cx="49.65" cy="60.72" r="6.42"/>
            <path class="cls-2" d="m85.01,63.7h23.45c2.41,0,2.42-3.75,0-3.75h-23.45c-2.41,0-2.42,3.75,0,3.75h0Z"/>
            <path class="cls-2" d="m85.01,70.7h23.45c2.41,0,2.42-3.75,0-3.75h-23.45c-2.41,0-2.42,3.75,0,3.75h0Z"/>
            <path class="cls-2" d="m85.01,82.03h23.45c2.41,0,2.42-3.75,0-3.75h-23.45c-2.41,0-2.42,3.75,0,3.75h0Z"/>
            <path class="cls-2" d="m85.01,89.03h23.45c2.41,0,2.42-3.75,0-3.75h-23.45c-2.41,0-2.42,3.75,0,3.75h0Z"/>
        </g>
        <g id="account" v-if="icon === 'account'">
            <path class="cls-2" d="m74.46,68.67c-13.68,0-24.78,17.8-24.78,39.76,0,3.45,11.09,6.24,24.78,6.24s24.78-2.79,24.78-6.24c0-21.96-11.09-39.76-24.78-39.76Z"/>
            <circle class="cls-2" cx="74.46" cy="49.13" r="13.26"/>
        </g>
        <g id="logout" v-if="icon === 'logout'">
            <path class="cls-2" d="m88.59,112.62h-29.29c-.73,0-1.45-.04-2.18-.06-1.26-.03,1.08.24-.14,0-.24-.05-.48-.08-.72-.13-.59-.12-1.16-.27-1.73-.46-.28-.09-.55-.2-.83-.29-.7-.22,1.09.55.08.03-.61-.31-1.2-.61-1.78-.97-.19-.12-.36-.25-.55-.37-.96-.6.8.75-.02-.01-.35-.33-.71-.64-1.05-.99-.18-.19-.35-.39-.52-.59-.69-.76.39.74.12.15-.22-.49-.59-.97-.85-1.45-.52-.93.33,1.1.03.08-.06-.19-.13-.38-.18-.57-.07-.24-.11-.49-.18-.73-.2-.76.27-.33.02.28.1-.23-.04-.71-.04-1-.04-3,0-5.99,0-8.99v-50.47c0-.34,0-.69,0-1.03,0-.04,0-.09,0-.13,0-.2.02-.41.02-.62,0-1.08-.29,1.17-.03.12.13-.53.34-1.07.45-1.6.12-.64-.58,1.11-.09.22.1-.18.18-.36.28-.54.15-.27.33-.52.48-.78.43-.73-.12.25-.23.26.23-.04.84-.95,1.06-1.17s.48-.43.71-.65c.71-.65-.87.55.02-.01.47-.3.93-.61,1.42-.88.2-.11.4-.21.6-.31l.31-.15c.5-.22.42-.19-.24.1.26-.31,1.3-.45,1.68-.55.58-.16,1.18-.27,1.77-.39,1.23-.24-1.12.06.14,0,.25-.01.5-.04.75-.05s.52-.01.79-.02c.17,0,.33,0,.5,0h28.85c2.62,0,5.12-2.3,5-5s-2.2-5-5-5c-9.73,0-19.45-.03-29.18,0s-19.93,6.48-20.07,17.05c-.04,3.07,0,6.14,0,9.2v50.75c0,2.87.58,5.62,2,8.14,2.73,4.86,8.11,8.22,13.55,9.13,5.08.85,10.46.42,15.58.42h18.69c2.62,0,5.12-2.3,5-5s-2.2-5-5-5h0Z"/>
            <path class="cls-2" d="m68.14,78.52h37.4c1.96,0,3.84-1.72,3.75-3.75s-1.65-3.75-3.75-3.75h-37.4c-1.96,0-3.84,1.72-3.75,3.75s1.65,3.75,3.75,3.75h0Z"/>
            <g>
                <polygon class="cls-2" points="112.21 75.27 100.4 82.09 88.59 88.9 88.59 75.27 88.59 61.63 100.4 68.45 112.21 75.27"/>
                <path class="cls-2" d="m110.95,73.11c-6.26,3.62-12.52,7.23-18.79,10.85-1.61.93-3.22,1.86-4.83,2.79l3.76,2.16v-27.27l-3.76,2.16c6.26,3.62,12.52,7.23,18.79,10.85,1.61.93,3.22,1.86,4.83,2.79,2.79,1.61,5.31-2.71,2.52-4.32-6.26-3.62-12.52-7.23-18.79-10.85l-4.83-2.79c-1.64-.95-3.76.26-3.76,2.16v27.27c0,1.9,2.12,3.11,3.76,2.16,6.26-3.62,12.52-7.23,18.79-10.85,1.61-.93,3.22-1.86,4.83-2.79,2.79-1.61.27-5.93-2.52-4.32Z"/>
            </g>
        </g>
        <circle class="cls-1" cx="74.46" cy="74.46" r="72.96"/>
    </svg>
</template>

<script>
export default {
    name: 'DashboardIcon',
    props: {
        icon: String
    }
}
</script>

<style lang="scss" scoped>
    .cls-1 {
        fill: none;
        stroke-miterlimit: 10;
        stroke-width: 3px;
    }

    .cls-2 {
        stroke-width: 0px;
    }
</style>