<template>
    <div class="d-flex justify-content-center flex-wrap bg-white">
        <div class="col-12 d-flex justify-content-between flex-wrap p-4 border rounded-4 shadow-lg">
            <div class="col-12 mb-4">
                <h1 class="text-uppercase text-third">
                    {{ details.name }}
                </h1>
            </div>
            <div class="col-12 col-sm-5 d-flex align-items-center flex-wrap mb-4">
                <img :src="require(`@/assets/img/card_schemes/${details.label}.svg`)" class="card-scheme">
                <h3 class="text-second">
                    {{ details.truncated_pan }}
                </h3>
                <i class="fa-solid fa-mobile-screen-button fs-2 ms-2 text-third" v-if="details.form_factor === 'DEVICE'"></i>
            </div>
            <div class="col-12 col-sm-5 mb-4">
                <span class="text-uppercase">
                    {{ $t('Dashboard.Tickets.Modals.TicketDetails.amount') }}
                </span>
                <h5 class="text-third text-break">
                    {{ details.amount }}
                </h5>
            </div>
            <div class="col-12 mb-4">
                <span class="text-uppercase">
                    {{ $t('Dashboard.Tickets.Modals.TicketDetails.id') }}
                </span>
                <h5 class="text-third text-break">
                    {{ details.id }}
                </h5>
            </div>
            <div class="col-12 col-sm-5 mb-4">
                <span class="text-uppercase">
                    {{ $t('Dashboard.Tickets.Modals.TicketDetails.first_tap') }}
                </span>
                <h5 v-for="(tap, index) in details.first_tap" :key="index" class="mb-2">
                    <span class="badge rounded-3 bg-third me-2">
                        {{ tap.key }}
                    </span>
                    <span class="badge rounded-3 bg-second">
                        {{ tap.val }}
                    </span>
                </h5>
            </div>
            <div class="col-12 col-sm-5 mb-4">
                <span class="text-uppercase">
                    {{ $t('Dashboard.Tickets.Modals.TicketDetails.last_tap') }}
                </span>
                <h5 v-for="(tap, index) in details.last_tap" :key="index" class="mb-2">
                    <span class="badge rounded-3 bg-third me-2">
                        {{ tap.key }}
                    </span>
                    <span class="badge rounded-3 bg-second">
                        {{ tap.val }}
                    </span>
                </h5>
            </div>
            <div class="col-12 col-sm-5 mb-4">
                <span class="text-uppercase">
                    {{ $t('Dashboard.Tickets.Modals.TicketDetails.valid_from') }}
                </span>
                <h5 class="text-third text-break">
                    {{ details.valid_from }}
                </h5>
            </div>
            <div class="col-12 col-sm-5 mb-4">
                <span class="text-uppercase">
                    {{ $t('Dashboard.Tickets.Modals.TicketDetails.valid_to') }}
                </span>
                <h5 class="text-third text-break">
                    {{ details.valid_to }}
                </h5>
            </div>
            <div class="col-12 d-flex justify-content-center flex-wrap">
                <div class="col-12 col-sm-5">
                    <span class="btn btn-auth btn-auth-second w-100" @click="$emit('close')">
                        {{ $t('Dashboard.Tickets.Modals.TicketDetails.close_details') }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TicketDetails',
    props: {
        details: Object
    }
}
</script>