<template>
    <div class="d-flex justify-content-center flex-wrap bg-white">
        <div class="col-12 d-flex justify-content-between align-items-center flex-wrap p-4 border rounded-4 shadow-lg">
            <div class="col-12">
                <h1 class="text-uppercase text-third">
                    {{ $t('Dashboard.BankCards.Modals.BankCardDetails.modalName') }}
                </h1>
            </div>
            <div class="col-12 d-flex align-items-center flex-wrap my-4">
                <img :src="require(`@/assets/img/card_schemes/${details.label}.svg`)" class="card-scheme">
                <h3 class="text-second">
                    {{ details.truncated_pan }}
                </h3>
                <i class="fa-solid fa-mobile-screen-button fs-2 ms-2 text-third" v-if="details.form_factors_name === 'DEVICE'"></i>
            </div>
            <div class="col-12 col-sm-5 mb-4">
                <span class="text-uppercase">
                    {{ $t('Dashboard.BankCards.Modals.BankCardDetails.alias_pan') }}
                </span>
                <h5 class="text-third text-break">
                    {{ details.alias_pan }}
                </h5>
            </div>
            <div class="col-12 col-sm-5 mb-4">
                <span class="text-uppercase">
                    {{ $t('Dashboard.BankCards.Modals.BankCardDetails.aid') }}
                </span>
                <h5 class="text-third text-break">
                    {{ details.aid }}
                </h5>
            </div>
            <div class="col-12 col-sm-5 mb-4">
                <span class="text-uppercase">
                    {{ $t('Dashboard.BankCards.Modals.BankCardDetails.created') }}
                </span>
                <h5 class="text-third text-break">
                    {{ details.created }}
                </h5>
            </div>
            <div class="col-12 col-sm-5 mb-4">
                <span class="text-uppercase">
                    {{ $t('Dashboard.BankCards.Modals.BankCardDetails.updated') }}
                </span>
                <h5 class="text-third text-break">
                    {{ details.updated }}
                </h5>
            </div>
            <div class="col-12 d-flex justify-content-center flex-wrap">
                <div class="col-12 col-sm-5">
                    <span class="btn btn-auth btn-auth-second w-100" @click="$emit('close')">
                        {{ $t('Dashboard.BankCards.Modals.BankCardDetails.close_details') }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BankCardDetails',
    props: {
        details: Object
    }
}
</script>