<template>
    <div class="d-flex justify-content-center flex-wrap">
        <div class="col-12 col-lg-10 text-center text-lg-end mb-4">
            <router-link to="/dashboard/travels/bank_cards/add" class="btn btn-auth btn-auth-second text-decoration-none">
                {{ $t('Dashboard.BankCards.addBankCard') }}
            </router-link>
        </div>
        <div class="col-12 col-lg-10">
            <DataTable :tbody="BankCards" @getDetails="details = $event" v-if="!loader && BankCards"/>
        </div>
    </div>
    <transition name="fade-modal">
        <modal v-if="details" @close="details = null">
            <template v-slot:body>
                <BankCardDetails :details="details" @close="details = null"/>
            </template>
        </modal>
    </transition>
</template>

<script>
import { mapState } from 'vuex'
import DataTable from "@/components/DataTables/BankCardsDataTable.vue"
import Modal from "@/components/Modal.vue"
import BankCardDetails from '../../../../components/Dashboard/Travels/Modals/BankCardDetails.vue'
export default {
    name: 'BankCards',
    components: {
        DataTable,
        Modal,
        BankCardDetails
    },
    computed: {
        ...mapState(['loader', 'BankCards'])
    },
    data() {
        return {
            details: null
        }
    }
}
</script>