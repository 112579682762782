<template>
    <div class="tokenizer-container">
        <div class="d-flex flex-wrap tokenizer p-4" v-if="SIARequest">
            <div class="col-12 text-center">
                <img :src="require(`@/assets/img/card_schemes/Nexi-Sia.jpg`)" class="w-25">
            </div>
            <div class="col-12 text-center text-white">
                <p>
                    {{ $t('tokenizer.SIA.title') }}
                </p>
                <p>
                    {{ $t('tokenizer.SIA.transaction') }}
                </p>
            </div>
            <div class="col-12 text-center">
                <form name="frm" id="frm" method="post" :action="config.tokenizer.SIA.ENDPOINT">
					<input type="hidden" name="EMAIL" :value="SIARequest.EMAIL">
					<input type="hidden" name="SHOPEMAIL" :value="SIARequest.SHOPEMAIL">
					<input type="hidden" name="AMOUNT" :value="config.tokenizer.SIA.AMOUNT">
					<input type="hidden" name="CURRENCY" :value="config.tokenizer.SIA.CURRENCY">
					<input type="hidden" name="SHOPID" :value="config.tokenizer.SIA.SHOPID">
					<input type="hidden" name="ORDERID" :value="SIARequest.ORDERID">
					<input type="hidden" name="OPTIONS" value="M">
					<input type="hidden" name="URLDONE" :value="SIARequest.URLDONE">
					<input type="hidden" name="URLMS" :value="config.tokenizer.SIA.URLMS">
					<input type="hidden" name="URLBACK" :value="config.tokenizer.SIA.URLBACK">
					<input type="hidden" name="ACCOUNTINGMODE" :value="config.tokenizer.SIA.ACCOUNTINGMODE">
					<input type="hidden" name="AUTHORMODE" :value="config.tokenizer.SIA.AUTHORMODE">
					<input type="hidden" name="MAC" :value="SIARequest.MAC">
					<input type="hidden" name="REQ" :value="SIARequest.request">
                    <button type="submit" class="btn btn-lg btn-light">
                        {{ $t('tokenizer.SIA.proceed') }}
                    </button>
				</form>
            </div>
        </div>
    </div>
</template>

<script>
const { v4: uuidv4 } = require('uuid')
import crypto from 'crypto-js'
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
    name: 'SIATokenizer',
    computed: {
        ...mapState([ 'config', 'account']),
        ...mapGetters(['apiPath', 'omsApiKey'])
    },
    data() {
        return {
            SIARequest: null
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer']),
        getSIARequest() {
            var siaRequest = {
                URLDONE: this.config.tokenizer.SIA.URLDONE,
                ORDERID: uuidv4(),
                EMAIL: this.account.email,
                SHOPEMAIL: this.config.tokenizer.SIA.SHOPEMAIL,
                request : null,
                MAC: null
            }

            var request = `URLMS=${this.config.tokenizer.SIA.URLMS}&URLDONE=${siaRequest.URLDONE}&ORDERID=${siaRequest.ORDERID}&SHOPID=${this.config.tokenizer.SIA.SHOPID}&AMOUNT=${this.config.tokenizer.SIA.AMOUNT}&CURRENCY=${this.config.tokenizer.SIA.CURRENCY}&ACCOUNTINGMODE=${this.config.tokenizer.SIA.ACCOUNTINGMODE}&AUTHORMODE=${this.config.tokenizer.SIA.AUTHORMODE}&OPTIONS=M`

            siaRequest.request = request

            var hash = crypto.HmacSHA256(request, this.config.tokenizer.SIA.APIKEY_BEGIN)
            var MAC = crypto.enc.Hex.stringify(hash)
            siaRequest.MAC = MAC

            this.SIARequest = siaRequest
        }
    },
    created() {
        this.getSIARequest()

        // Response request is DONE
        // https://dev2.mytravels.openmediasimple.com/dashboard/travels/bank_cards/add?ORDERID=490fc080-5a38-4f5f-b46e-1c124c325de2&SHOPID=12928093D300001&AUTHNUMBER=H32922&AMOUNT=2&CURRENCY=978&TRANSACTIONID=8032112928SL2d50t6n8ckql7&ACCOUNTINGMODE=I&AUTHORMODE=I&RESULT=00&TRANSACTIONTYPE=TT07&PANALIASREV=NULL&PANALIAS=NO_TOKEN&PANALIASEXPDATE=2402&PANALIASTAIL=2874&NETWORK=02&MAC=317b6197258d3992818665281566f9b66845a6a3ea30615c534c0273199414c3
    }
}
</script>

<style lang="scss" scoped>
    .tokenizer-container {
        height: 500px;
        background-color: #2d32aa;
        background-image: url('../assets/img/load.gif');
        background-size: 20%;
        background-position: center;
        background-repeat: no-repeat;
        overflow: auto;

        .tokenizer {
            height: 100%;
            background-color: #2d32aa;
        }
    }
</style>