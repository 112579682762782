<template>
    <div class="d-flex justify-content-center flex-wrap bg-white">
        <div class="col-12 d-flex justify-content-between align-items-center flex-wrap p-4 border rounded-4 shadow-lg">
            <div class="col-12">
                <h1 class="text-uppercase text-third">
                    {{ details.Payments.short_id }}
                </h1>
            </div>
            <div class="col-12 d-flex align-items-center flex-wrap my-4">
                <img :src="require(`@/assets/img/card_schemes/${details.BankCard.label}.svg`)" class="card-scheme">
                <h3 class="text-second">
                    {{ details.BankCard.truncated_pan }}
                </h3>
                <i class="fa-solid fa-mobile-screen-button fs-2 ms-2 text-third" v-if="details.BankCard.form_factors_name === 'DEVICE'"></i>
            </div>
            <div class="col-12 mb-4">
                <span class="text-uppercase">
                    {{ $t('Dashboard.Payments.Modals.PaymentDetails.token') }}
                </span>
                <h5 class="text-third text-break">
                    {{ details.BankCard.token }}
                </h5>
            </div>
            <div class="col-12 col-sm-5 mb-4">
                <span class="text-uppercase">
                    {{ $t('Dashboard.Payments.Modals.PaymentDetails.amount') }}
                </span>
                <h5 class="text-third text-break">
                    {{ details.Payments.amount }}
                </h5>
            </div>
            <div class="col-12 col-sm-5 mb-4">
                <span class="text-uppercase">
                    {{ $t('Dashboard.Payments.Modals.PaymentDetails.status') }}
                </span>
                <h5 class="text-third text-break">
                    {{ details.LastCharge.charge_results_name }}
                </h5>
            </div>
            <div class="col-12 col-sm-5 mb-4">
                <span class="text-uppercase">
                    {{ $t('Dashboard.Payments.Modals.PaymentDetails.created') }}
                </span>
                <h5 class="text-third text-break">
                    {{ details.Payments.created }}
                </h5>
            </div>
            <div class="col-12 col-sm-5 mb-4">
                <span class="text-uppercase">
                    {{ $t('Dashboard.Payments.Modals.PaymentDetails.updated') }}
                </span>
                <h5 class="text-third text-break">
                    {{ details.Payments.updated }}
                </h5>
            </div>
            <div class="col-12 d-flex justify-content-between align-items-center flex-wrap">
                <div class="col-12 col-sm-5">
                    <span class="btn btn-auth btn-auth-second w-100" @click="$emit('close')">
                        {{ $t('Dashboard.Payments.Modals.PaymentDetails.close_details') }}
                    </span>
                </div>
                <div class="col-12 col-sm-5" v-if="details.LastCharge.charge_results_name == 'SUCCEEDED'">
                    <span class="btn btn-auth btn-pulse text-uppercase w-100" @click="$emit('getReceipt', details.Payments.short_id)">
                        {{ $t('Dashboard.Payments.Modals.PaymentDetails.view_receipt') }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PaymentDetails',
    props: {
        details: Object
    }
}
</script>