<template>
    <div class="d-flex justify-content-center flex-wrap my-5">
        <div class="col-12 col-lg-10">
            <DataTable :date="selectedDate" :tbody="tickets" @filterByDate="filterByDate" @getDetails="details = $event" v-if="!loader && tickets"/>
        </div>
    </div>
    <transition name="fade-modal">
        <modal v-if="details" @close="details = null">
            <template v-slot:body>
                <TicketDetails :details="details" @close="details = null"/>
            </template>
        </modal>
    </transition>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import DataTable from "@/components/DataTables/TicketsDataTable.vue"
import Modal from '@/components/Modal.vue'
import TicketDetails from '../../../../components/Dashboard/Travels/Modals/TicketDetails.vue'
export default {
    name: 'TicketsView',
    components: {
        DataTable,
        Modal,
        TicketDetails
    },
    computed: {
        ...mapState(['loader', 'account']),
        ...mapGetters(['apiPath', 'omsApiPath', 'omsApiKey'])
    },
    data() {
        return {
            selectedDate: null,
            tickets: null,
            details: null
        }
    },
    methods: {
        ...mapMutations(['setLoader', 'setErrorFromServer']),
        getTickets(startDate, endDate) {
            this.setLoader(true)
            if (!startDate && !endDate) {
                startDate = moment().subtract(29, 'days').format('YYYY-MM-DD')
                endDate = moment().format('YYYY-MM-DD')
            }
            this.$http
                .post(`${this.apiPath}/bank_cards/get`, { accounts_id: this.account.id }, {
                    headers: {
                        'x-api-key': this.omsApiKey
                    }
                })
                .then((res) => {
                    var bank_cards = res.data
                    var AliasPanPath = ''
                    for (var i = 0; i < bank_cards.length; i++) {
                        var bank_card = bank_cards[i]
                        AliasPanPath += `${bank_card.alias_pan}/`
                    }
                    this.$http
                        .get(`${this.omsApiPath}/tickets/get/${this.omsApiKey}/10/0/3:desc/${startDate}/${endDate}/${AliasPanPath}`)
                        .then((res) => {
                            var tickets = res.data.payload
                            for (var i = 0; i < tickets.length; i++) {
                                var ticket = tickets[i]
                                ticket.label = this.$formatLabel(ticket.label)
                                ticket.amount = this.$formatAmount(ticket.amount, ticket.currency)
                                
                                if (ticket.first_tap)  {
                                    var first_tap_data = []
                                    for (const [key, val] of Object.entries(ticket.first_tap)) {
                                        first_tap_data.push({key, val})
                                    }
                                    ticket.first_tap = first_tap_data
                                }

                                if (ticket.last_tap)  {
                                    var last_tap_data = []
                                    for (const [key, val] of Object.entries(ticket.last_tap)) {
                                        last_tap_data.push({key, val})
                                    }
                                    ticket.last_tap = last_tap_data
                                }

                                ticket.valid_from = this.$formatDate(ticket.valid_from, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                ticket.valid_to = this.$formatDate(ticket.valid_to, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                            }
                            this.selectedDate = `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`
                            this.tickets = tickets
                            this.setLoader(false)
                        })
                        .catch((err) => {
                            this.setErrorFromServer(err.message)
                            this.setLoader(false)
                        })
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                    this.setLoader(false)
                })
        },
        filterByDate(when) {
            this.tickets = null
            this.getTickets(when.startDate, when.endDate)
        }
    },
    created() {
        this.getTickets()
    }
}
</script>